class ZIDXAccountLeadEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLeadEditContainer";
    }
    getMatchURL(){
        return "/account/leads/edit";
    }
    render(){
        // console.log("lead edit");
        ZIDX.$(".zidxAccountLeadEditContainer #lead_assigned_user_id").on("change", function(this:HTMLInputElement) {
            ZIDX.$(".zidxAccountLeadEditContainer #notify1")[0].checked=true;
        });
    }
}